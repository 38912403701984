.fr {
  display: flex;
  flex-direction: row;
}

.frr {
  display: flex;
  flex-direction: row-reverse;
}

.fc {
  display: flex;
  flex-direction: column;
}

.frc {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.fcc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.rotate-r1 {
  transform: rotate(10deg);
}

.rotate-r2 {
  transform: rotate(20deg);
}

.rotate-r3 {
  transform: rotate(30deg);
}

.rotate-l1 {
  transform: rotate(-10deg);
}

.rotate-l2 {
  transform: rotate(-20deg);
}

.rotate-l3 {
  transform: rotate(-30deg);
}

.leaflet-container {
  height: 500px;
  width: 100%;
  border: none;
}
@import 'leaflet/dist/leaflet.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@import './css/table.css';
@import './css/customClasses.css';

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --site-red: #932933;
}

html,
body,
#root,
main,
.App {
  @apply bg-white !important;
  width: 100%;
  height: 100%;
}

main {
  margin-top: 1em;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 92vh;
}

.code-font {
  font-family: monospace;
}

.active {
  position: relative;
}

.mobileNavBar > * > * .active::after {
  content: '';
  position: absolute;
  top: 0;
  right: 103%;
  width: 100%;
  height: 100%;
  border-right: 120px solid var(--site-red);
  border-top: none;
  border-radius: 5px;
}

.mobActive {
  position: relative;
}

.mobActive::after {
  content: '';
  position: absolute;
  top: 0;
  right: 110%;
  width: 100%;
  height: 90%;
  border-right: 120px solid var(--site-red);
  border-top: none;
  border-radius: 5px;
}

.mobileNavBar > * > * > .NavLink {
  padding-left: 0.22em;
}

.mobileNavBar > * > * .active::before {
  display: none;
}

.mobileNavBar > * > * .active {
  position: relative;
}

.mobileNavBar {
  opacity: 1;
  z-index: 30;
}

.stackRow:nth-child(2n) {
  flex-basis: 100%;
}

.projectImage {
  z-index: 5;
}

.projectInfoBox {
  z-index: 12;
}

.rotateElement {
  writing-mode: vertical-rl;
  text-align: center;
  transform: rotate(180deg);
}

.shadowTop {
  box-shadow: 0 0px 25px -8px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
}

.navContentMob {
  background: white;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 40%,
    rgba(255, 255, 255, 0) 100%
  );
}

.navbarUnderline {
  position: absolute;
  top: 0;
  right: 110%;
  width: 100%;
  height: 90%;
  border-right: 120px solid var(--site-red);
  border-top: none;
  border-radius: 5px;
}

.childOverParent {
  top: -0.5em;
  right: 0;
  bottom: 0.5em;
  left: 0;
  margin: auto;
}

.NavLink {
  display: inline-block;
  position: relative;
}

.NavLink:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: var(--site-red);
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.NavLink:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.swalContainer > * {
  color: black !important;
}

.navLogoBox {
  z-index: 0;
  background: white;
  width: 120px;
  height: 50px;
  top: 0;
  left: -15px;
  position: absolute;
}

.navLogo {
  z-index: 10;
  position: relative;
}

.mobNavWrapper {
  z-index: 500 !important;
  background-color: white !important;
  height: 110vh;
}

.contactFormModal {
  z-index: 300;
  margin: 0 !important;
  width: 100vw;
  height: 100vh;
}

.desktopNavTrialButton {
  padding: 10px 15px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen only and (min-width: 768px) {
  .navMain {
    z-index: 100;
  }
}

.promoModal {
  height: 100vh !important;
  width: 100vw !important;
  position: fixed;
  z-index: 2000 !important;
  margin: 0 !important;
  background-color: rgba(44, 44, 44, 0.6);
}

.promoModalContainer {
  opacity: 1 !important;
}

.bored {
  border: 2px solid red;
}

@media only screen and (max-width: 500px) {
  .instructorPicture {
    height: auto !important;
    object-fit: cover;
    padding-left: none !important;
    padding-right: none !important;
  }
}

@media only screen and (min-width: 1024px) {
  .testimonialsWrapper:nth-child(2) > div {
    flex-direction: row-reverse;
  }
}
